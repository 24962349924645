<template>
  <div class="food-nutrition-detail">
    <div class="card-box">
      <div class="food-info">
        <van-image
          @click="preview([imageUrl]);"
          class="food-image"
          width="100px"
          height="100px"
          fit="cover"
          :error-icon="require('../../../public/imgs/nutritionQuery/food_default_cover.png')"
          :src="imageUrl"
        />
        <div class="food-name">
          <h3>{{ name }}</h3>
          <div class="food-tags">
            <span v-for="(tag, index) of tags" :key="index" v-bind:class="tagClass(tag)">{{ tag }}</span>
          </div>
        </div>
      </div>
      <van-divider />
      <van-cell title="NUTRIPACKS专业评估 解锁更多营养方式" is-link url="https://api.xiaojudai.cn/api/s/2" />
    </div>

    <div class="card-box nutrient-element">
        <div class="title" v-if="enercCal > 0">
        <h1>热量</h1>
        <div class="switch-unit">
          <button v-for="unit of units"
            :key="unit"
            @click="switchUnit(unit)"
            :class="{ 'active-unit': activeUnit === unit }">
            {{ unit }}
          </button>
        </div>
      </div>
      <div class="heat" v-if="enercCal > 0">
          <p><span>{{ enerc }}</span> {{ activeUnit }}</p>
        <p>每100g（可食用部分）</p>
      </div>
      <div class="nutrient-element-list">
        <ul class="three-nutrient-elements">
          <li>
            <div class="outer-border">
              <div class="inner-box">
                  <span>{{enerc_cal_chocdf_nfd}}%</span>
                <div class="fill-round" :style="`height: ${enerc_cal_chocdf_nfd}%`"></div>
              </div>
            </div>
            <p>碳水化合物</p>
          </li>

          <li>
            <div class="outer-border">
              <div class="inner-box">
                  <span>{{enerc_cal_procnt_nfd}}%</span>
                <div class="fill-round" :style="`height: ${enerc_cal_procnt_nfd}%`"></div>
              </div>
            </div>
            <p>蛋白质</p>


          </li>


          <li>
            <div class="outer-border">
              <div class="inner-box">
                  <span>{{enerc_cal_fat_nfd}}%</span>
                <div class="fill-round" :style="`height: ${enerc_cal_fat_nfd}%`"></div>
              </div>
            </div>
            <p>脂肪</p>
          </li>

        </ul>

      <van-divider  v-if="enercCal > 0"/>
      <div class="title">
        <h1>营养元素</h1>
      </div>

        <ul class="other-nutrient-elements">
          <li v-for="(item, index) of otherNutrientElements" :key="index">
              <span :class="item.indent ? 'indent':''">{{ item.name }}</span>
            <span v-if="item.weight != null">{{ item.weight }}{{ item.unit }}</span>
            <span v-if="item.weight == null">-</span>
          </li>
        </ul>

        <router-link :to="{ name: 'moreNutrientElements', query: { id: id }}">
          <button class="more-nutrient-elements-button">更多营养元素<van-icon name="arrow" /></button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { ImagePreview } from 'vant';
  export default {
    name: 'FoodNutritionDetail',
    mounted(){
        document.title = "食品营养素查询"
        this.getData();
    },
    methods: {
        preview(url){
            ImagePreview([url]);
        },
        switchUnit(unit){
            this.activeUnit = unit;
            if(this.activeUnit == "卡"){
                this.enerc = this.enercCal;
            }else{
                this.enerc = parseFloat(this.enercKj).toFixed(0);
            }
        },
        tagClass(tag){
            if(tag.indexOf("高") !== -1){
                return "red";
            }
            return "";
        },
        isIndent(name){
            if(["饱和脂肪酸", "单不饱和脂肪酸", "多不饱和脂肪酸", "反式脂肪酸", "不可溶性膳食纤维", "可溶性膳食纤维"].indexOf(name) != -1){
                console.log(name);
                return true;
            }
            return false;
        },
        getData(){
            var that = this;
            that.id = this.$route.query.id;
            //this.$ajax.post('http://api.np.local:7777/api/food/detail', {
            this.$ajax.post(this.API.api.apiurl.getFoodDetail, {
                id: that.id,
            },{
            }).then(res => {
                var data = res.data.data;
                that.id = data.id;
                that.tags = data.food_highlight;
                that.name = data.name;
                that.imageUrl = data.image_url;
                that.enerc = data.enerc_cal;

                that.enerc_cal_chocdf_nfd = data.enerc_cal_chocdf_nfd
                that.enerc_cal_fat_nfd    = data.enerc_cal_fat_nfd
                that.enerc_cal_procnt_nfd = data.enerc_cal_procnt_nfd

                that.enercCal = data.enerc_cal;
                that.enercKj = parseFloat(data.enerc_kj).toFixed(3)

                document.title = data.name+"的热量，蛋白质，脂肪，碳水化合物各营养素含量"


                var threeNutrientElements = []
                var otherNutrientElements = []
                data.facts.forEach(el => {
                    if(["碳水化合物", "蛋白质", "脂肪"].indexOf(el.name) != -1){
                        threeNutrientElements.push({name:el.name, percentage:el.weight});
                    }
                    if(["碳水化合物", "蛋白质", "脂肪", "反式脂肪酸", "糖", "膳食纤维", "钠"].indexOf(el.name) != -1){
                        if(that.isIndent(el.name)){
                            el.name = "-" + el.name;
                            el.indent = true;
                        }
                        otherNutrientElements.push(el);
                    }

                });

                var total = 100;
                threeNutrientElements.forEach(el => {
                    el.percentage = parseFloat(el.percentage/total * 100).toFixed(2) * 100 / 100;
                });
                that.threeNutrientElements = threeNutrientElements;
                that.otherNutrientElements = otherNutrientElements;
            });
        }
    },
    data() {
        return {
            id: 0,
            name : "",
            imageUrl:"",
            enerc:0,
            enercCal:0,
            enercKj:0,
            activeUnit: '卡',
            units: ['卡', '千焦'],
            tags: [],
            threeNutrientElements: [
            ],
            otherNutrientElements: [
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.food-nutrition-detail {
  padding: 35px 20px;

  .card-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .food-info {
    display: flex;
    align-items: center;
    width: 100%;

    .food-image {
      margin-top: -31px;
      margin-right: 16px;
    }

    .food-name {
      flex: 1;
      margin-top: -9px;

      h3 {
        font-size: 16px !important;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 23px !important;
        text-align: left;
        margin-bottom: 7px;
      }

      .food-tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span {
          display: inline-block;
          width: 50px;
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #141414;
          line-height: 20px;
          text-align: center;
          background-color: #E6E6E6;
          border-radius: 3px;
          margin-right: 7px;
          margin-bottom: 7px;

          &.red {
            background-color: #EBD2D9;
          }
        }
      }
    }
  }

  .van-cell {
    padding: 0;

    .van-cell__title {
      text-align: left;
      font-size: 12px;
    }
  }

  .nutrient-element {
    padding: 24px 16px;
  }

  .title {
    display: flex;
    align-items: center;

    h1 {
      flex: 1;
      text-align: left;
      font-size: 19px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
      line-height: 27px !important;
    }

    .switch-unit {
      display: flex;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 22px;
        border-radius: 11px;
        border: 1px solid #CCCCCC;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 17px;

        &:first-of-type {
          margin-right: 10px;
        }
      }

      .active-unit {
        background-color: #C25E4D;
        color: #fff;
      }
    }
  }

  .heat {
    margin-top: 20px;

    span {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
      line-height: 34px;
    }

    p:last-of-type {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }

  .three-nutrient-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 32px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      .outer-border {
        position: relative;
        width: 80px;
        height: 80px;
        margin-bottom: 18px;
        border: 5px solid #CCCCCC;
        border-radius: 50%;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -13px;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 13px 10.5px 0 10.5px;
          border-color: #CCCCCC transparent transparent transparent;
        }
      }

      .inner-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border:  5px solid #fff;
        border-radius: 50%;
        overflow: hidden;
      }

      span {
        position: absolute;
        z-index: 2;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 23px;
      }

      .fill-round {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: #B9C7BB;
      }

      p {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 19px;
      }

      &:nth-of-type(2) {
        .fill-round {
          background-color: #FFDE97;
        }
      }

      &:nth-of-type(3) {
        .fill-round {
          background-color: #C8D0D8;
        }
      }
    }
  }

  .other-nutrient-elements {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px dashed #141414;
      &:last-of-type {
        border: none;
      }

      span {
      &.indent{
          font-size:10px;
      }
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 24px;
      }
    }
  }

  .more-nutrient-elements-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 154px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #B9C7BB !important;
    background-color: transparent;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
  }
}
</style>
